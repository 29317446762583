import {Dropdowns} from "./Dropdowns/Dropdowns";
import Utilities from "./Utilities";

export default class GetAddress {

    static initGetAddress(selector) {
        $('[postcode-search]', selector).each( function () {
            $(this).on('keyup', Utilities.delay(e => {
                GetAddress.getAddress($(this));
            }, 1000));
        });
    }

    static getAddress(pCodeInput) {

        let $input = $(pCodeInput);

        if($input.val().length < 2)
            return false;

        $input.closest('.form-group').addClass('loading');

        $.ajax({
            url: $input.data('src'),
            data: {'input' : $input.val()},
            type: 'GET',
            timeout: 5000,
            success: function (addressData) {
                $input.siblings('.text-danger').remove()
                if ($input.closest('.form-group').prev().find('select[name="postcodeLookup"]').length)
                    $input.closest('.form-group').prev().remove()
                if(addressData.message == 'error') {
                    $('<small class="text-danger">Postcode not found</small>').insertAfter($input);
                } else {
                    handleData(addressData, $input)
                }
            },
            error: function () {
                $input.siblings('.text-danger').remove()
                $input.siblings('.selectized').remove()
                $input.siblings('.selectize-control').remove()
                $('<small class="text-danger">Postcode not found</small>').insertAfter($input);
            },
            complete: function () {
                $input.closest('.form-group').removeClass('loading');
            }
        })

        function handleData(addressData, $input) {

            let $postcodeLoopup = $("<div class='form-group col-12 col-md-6 '>" +
                "<label for='postcodeLookup'>Postcode Lookup</label>" +
                "<select placeholder='Select Address' autocomplete='new-password' class='form-control initial mt-2' name='postcodeLookup'></select>" +
                "</div>");

            let selectHTML = $input.closest('.form-group').prev().find('select[name="postcodeLookup"]').length ?
                $input.closest('.form-group').prev().find('select[name="postcodeLookup"]') :
                $postcodeLoopup.insertBefore($input.closest('.form-group')).find('select[name="postcodeLookup"]')

            let containerSelector = $input.closest($input.data('container'));


            Dropdowns.selectize($(selectHTML));




            $('.selectize-dropdown', $(selectHTML).closest('.form-group')).prepend(''+
                '<div class="option selectize-toolbar">'+
                '<div class="btn-group btn-group-justified" role="group" aria-label="...">'+
                '  <div class="btn-group" role="group">'+
                '    <button type="button" class="btn btn-secondary newPostcodeSearch">New Postcode Search</button>'+
                '  </div>'+
                '</div>'+
                '</div>');

            $('.selectize-dropdown', $(selectHTML).closest('.form-group')).on('mousedown', '.selectize-toolbar .newPostcodeSearch', function (event) {
                event.preventDefault();
                return false;
            });
            $('.selectize-dropdown', $(selectHTML).closest('.form-group')).on('click', '.selectize-toolbar .newPostcodeSearch', function (event) {
                event.preventDefault();
                resetPostcode();
            });

            let sApi = $(selectHTML)[0].selectize;

            sApi.clear();
            sApi.clearOptions();

            Object.keys(addressData['addresses']).forEach(function (key) {
                sApi.addOption({text: addressData['addresses'][key]['fullAddress'], value : addressData['addresses'][key]['fullAddressUncut']})
            });

            sApi.open();

            sApi.on('change', function(value) {

                //catches blank
                if(value === '')
                    return false;

                let selectedAddressData = value.split(',')

                $('input[name*="line_1"]', containerSelector).val(selectedAddressData[0].trim());
                $('input[name*="line_2"]', containerSelector).val(selectedAddressData[1].trim());
                $('input[name*="line_3"]', containerSelector).val(selectedAddressData[2].trim());
                $('input[name*="city"]', containerSelector).val(selectedAddressData[5].trim());

                //@todo lat and lng too long in db
                // $('input[name*="longitude"]', containerSelector).val(addressData['lon']);
                // $('input[name*="latitude"]', containerSelector).val(addressData['lat']);

                let $countyEl;
                if(($countyEl = $(':input[name*="county"]', containerSelector)).length > 0) {
                    if($countyEl.is('select')) {
                        let countyApi = $(':input[name*="county"]', containerSelector)[0].selectize
                        countyApi.addItem(selectedAddressData[6].trim());
                        countyApi.setValue(selectedAddressData[6].trim());
                    } else {
                        $countyEl.val(selectedAddressData[6].trim());
                    }
                }

                if($(':input[name*="country"]', containerSelector).length > 0) {
                    let countryApi = $(':input[name*="country"]', containerSelector)[0].selectize
                    countryApi.addItem(window.gb_id.content);
                    countryApi.setValue(window.gb_id.content);
                }

                $input.trigger('change');
            });
            sApi.on('item_remove', function (value) {
                resetPostcode();
            });

            function resetPostcode() {
                sApi.destroy();
                $(selectHTML).closest('.form-group').remove();
                $(':input[name*="postcode"]', containerSelector).val('').focus();
            }

        }
    }
}
