import Utilities from "../Utilities";

export class TableViews {

    /**
     * Returns the given data in the format of a readable date.
     *
     * @param data
     * @param format
     * @returns {string}
     */
    static date(data, format = 'DD/MM/YYYY') {
        if (data) {
            let date = moment(data);

            return date.format(format);
        }
        return '';
    }

    /**
     * Returns the given data in the format of Currency.
     *
     * @param data
     * @param currencySymbol
     * @returns {string}
     */
    static monetary(data, currencySymbol = '£') {
        let twoDecimalPlaces = parseFloat(data.toString().replace(',', '')).toFixed(2);

        return currencySymbol + twoDecimalPlaces;
    }

    /**
     * Returns the given string in capitalised.
     *
     * @param data
     * @returns {string}
     */
    static smartCapitalise(data) {
        return data.replace(/\b\w/g, function (l) {
            return l.toUpperCase()
        })
    }

    /**
     * Returns the given data in the format of a readable difference from now.
     *
     * @param data
     * @param format
     * @returns {string}
     */
    static date_difference(data) {
        let date = moment(data);

        return moment.duration(date.diff()).humanize(true);
    }

    /**
     * Returns the given data in the format of a readable date & time.
     *
     * @param data
     * @param format
     * @returns {string}
     */
    static date_time(data, format = 'DD/MM/YYYY HH:mm') {
        let date = moment(data);

        return date.format(format);
    }

    /**
     * Returns an edit button for a table with the specified href.
     *
     * @param href
     * @param element
     * @param label
     * @param helpText
     * @param className
     * @returns {string}
     */
    static edit_btn(href, element = false, label = "", helpText = '', className = "table-button btn btn-primary") {

        return '<a href="' + href + '" class=" ' + className + '" ' + (element !== false ? 'data-modal data-element="' + element + '"' : '') + (helpText !== '' ? 'data-toggle="tooltip" title="' + helpText + '"' : '') + '>' + label + '</a>';
    }

    /**
     * Returns a delete button for a table with the specified href.
     *
     * @param href
     * @param inputs
     * @param confirmationMessage
     * @param className
     * @returns {string}
     */
    static delete_btn(href, inputs = '', confirmationMessage = 'Are you sure you want to delete?', className = 'table-button btn btn-danger') {

        return '<form action="' + href + '" method="POST" data-confirmation="message" data-confirm="' + confirmationMessage + '" data-confirmation="message" data-confirm-type="delete">' +
            '<input type="hidden" name="_method" value="DELETE">' +
            inputs +
            '<button type="submit" class="' + className + '" data-toggle="tooltip" title="Delete"><i class="fal fa-trash-alt"></i></button>' +
            '</form>';
    }

    /**
     * Hide Show Content
     * @param name
     * @param content
     * @returns {string}
     */
    static hide_show(name, content) {
        $(document).on('click', `#${name}btn`, function () {
            if ($(`#${name}`).css('display') == 'none') {
                $(`#${name}dots`).css('display', 'none');
                $(`#${name}`).css('display', 'inline');
                $(`#${name}icon`).removeClass('fa-eye');
                $(`#${name}icon`).addClass('fa-eye-slash');
            } else {
                $(`#${name}dots`).css('display', 'inline');
                $(`#${name}`).css('display', 'none');
                $(`#${name}icon`).removeClass('fa-eye-slash');
                $(`#${name}icon`).addClass('fa-eye');
            }
        });
        return `<div id="${name}" style="display: none">${content}</div><div id="${name}dots" style="display: inline">...</div><div class="d-inline"><button id="${name}btn" style="background: none"><i id="${name}icon" class="fas fa-eye"></i></button></div>`;
    }

    /**
     * Returns a notes button for a table with the specified href.
     *
     * @param href
     * @returns {string}
     */
    static notes_btn() {

        return '<div class="table-button btn btn-light" data-toggle="tooltip" type="notes" title="Notes"><i class="far fa-sticky-note"></i></div>';
    }

    /**
     * Returns a post form with a button with the specified href.
     *
     * @param href
     * @param text
     * @param buttonClass
     * @param formAttr
     * @param formHTML
     * @returns {string}
     */
    static update_form_btn(href, text, buttonClass = 'btn-success', formAttr = '', formHTML = '') {

        return '<form action="' + href + '" method="POST" ' + formAttr + ' >' +
            '<input type="hidden" name="_method" value="PATCH">' +
            formHTML +
            '<button type="submit" class="table-button btn ' + buttonClass + '">' + text + '</button>' +
            '</form>';
    }

    static wysiwyg(data) {

        let string = Utilities.tagAndHtmlStrip(data);

        return string.length > 500 ? string.substring(0, 200) + '...' : (string != null ? string : '');
    }

    static capitalise(string) {
        return string && string[0].toUpperCase() + string.slice(1)
    }
}
