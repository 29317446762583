import * as FilePond from 'filepond';

import FilePondPluginGetFile from 'filepond-plugin-get-file';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';


export class Filepond {

    static init(selector) {

        $(selector).find(':input[type="file"]:not(.filepond-template)').each(function () {
            let folder = $(this).data('folder'),
                name = $(this).attr('name'),
                options = {
                    allowRevert: false,
                    allowReorder: false,
                    server: {
                        load: (source, load, error, progress, abort, headers) => {
                            var myRequest = new Request(source);
                            fetch(myRequest).then(function(response) {
                                response.blob().then(function(myBlob) {
                                    load(myBlob)
                                });
                            });
                        },
                        process: (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {

                            // fieldName is the name of the input field
                            // file is the actual file object to send
                            const formData = new FormData();
                            formData.append("file", file, file.name);
                            formData.append('_token', window.csrf_token);
                            formData.append('folder', folder);

                            const request = new XMLHttpRequest();
                            request.open('POST', '/file-upload');

                            // Should call the progress method to update the progress to 100% before calling load
                            // Setting computable to false switches the loading indicator to infinite mode
                            request.upload.onprogress = (e) => {
                                progress(e.lengthComputable, e.loaded, e.total);
                            };

                            // Should call the load method when done and pass the returned server file id
                            // this server file id is then used later on when reverting or restoring a file
                            // so your server knows which file to return without exposing that info to the client
                            request.onload = function() {
                                if (request.status >= 200 && request.status < 300) {
                                    // the load method accepts either a string (id) or an object
                                    let responseJson = JSON.parse(request.responseText);
                                    if(responseJson.success == true)
                                        load(responseJson.file);
                                }
                                else {
                                    // Can call the error method if something is wrong, should exit after
                                    error('oh no');
                                }
                            };

                            request.send(formData);

                            // Should expose an abort method so the request can be cancelled
                            return {
                                abort: () => {
                                    // This function is entered if the user has tapped the cancel button
                                    request.abort();

                                    // Let FilePond know the request has been cancelled
                                    abort();
                                }
                            };
                        }
                    },
                    instantUpload: true,
                    imageTransformOutputQuality: 75,
                    allowDownloadByUrl: true
                };

            FilePond.registerPlugin(
                FilePondPluginImagePreview,
                FilePondPluginGetFile
            );

            if($(this).data('files') !== undefined) {
                let files = [];

                if(Array.isArray($(this).data('files'))) {
                    $.each($(this).data('files'), function (key, item) {
                        files.push({
                            source: "/download/" + item.id,
                            options: {
                                type: "local"
                            }
                        });
                    });

                } else {
                    let item = $(this).data('files');

                    files.push({
                        source: "/file-download/" + item.id,
                        options: {
                            type: "local"
                        }
                    });
                }

                options.files = files;
            }

            FilePond.create($(this)[0], options);

        });
    }
}

