import {TableViews} from "./TableViews";
import Utilities from "../Utilities";

export class TableParams {

    static usersTable(params, element) {
        return {
            columns: [
                {
                    title: "Name",
                    data: "name",
                    name: "name",
                    filter: {
                        type: "text"
                    },
                    responsivePriority: 1
                },
                {
                    title: "Email address",
                    data: "email",
                    name: "email",
                    filter: {
                        type: "text"
                    }
                },
                {
                    data: "id",
                    sortable: false,
                    className: "actions",
                    render: (data, type, row) => {
                        return TableViews.edit_btn("/settings/users/" + data + "/edit", "editUserCard", "<i class='fal fa-pencil-alt'></i>", "Edit")
                    },
                    responsivePriority: 2
                },
                {
                    data: "id",
                    sortable: false,
                    className: "actions",
                    render: (data, type, row) => {
                        return TableViews.delete_btn("/settings/users/" + data);
                    },
                    responsivePriority: 3
                }
            ]
        }
    }
    static customersTable(params, element) {

        return {
            columns: [
                {
                    title: "Name",
                    name: "name",
                    sortable: false,
                    filter: {
                        type: "text"
                    },
                    render: (data, type, row) => {
                        return (row.first_name+' '+row.last_name)
                    },
                    responsivePriority: 1,
                    defaultContent: ''
                },
                {
                    title: "Email address",
                    data: "email",
                    name: "email",
                    defaultContent: '',
                    sortable: false,
                    filter: {
                        type: "text"
                    }
                },
                {
                    title: "Postcode",
                    data: "address.postcode",
                    name: "postcode",
                    defaultContent: 'Unknown',
                    sortable: false,
                    filter: {
                        type: "text"
                    }
                },
                {
                    title: "Defense Number",
                    data: null,
                    name: "defense_value",
                    sortable: false,
                    render: (data, type, row) => {
                        if(row.f_m_a_membership)
                            return row.f_m_a_membership.value;

                        if(row.defence_membership)
                            return row.defence_membership.value;

                        return '';
                    },
                    filter: {
                        type: "text"
                    },
                    defaultContent: ''
                },
                {
                    title: "Defense Expiry",
                    data: null,
                    name: "defense_expiry",
                    sortable: false,
                    render: (data, type, row) => {
                        if(row.f_m_a_membership)
                            return TableViews.date(row.f_m_a_membership.expiry);

                        if(row.defence_membership)
                            return TableViews.date(row.defence_membership.expiry);

                        return '';
                    },
                    filter: {
                        type: "daterangepicker"
                    },
                    defaultContent: ''
                },
                {
                    title: "Mobile",
                    data: "mobilephone.value",
                    name: "mobilephone",
                    defaultContent: '',
                    sortable: false,
                    filter: {
                        type: "text"
                    }
                },
                {
                    title: "Epos Now",
                    data: "epos_now_linked",
                    name: "epos_now_linked",
                    render: data => data ? "Linked" : "Not Linked",
                    sortable: false,
                    filter: {
                        type: "epos_now_statuses"
                    }
                },
                {
                    data: "id",
                    sortable: false,
                    className: "actions",
                    render: (data, type, row) => {
                        return TableViews.edit_btn("/customers/" + data, false, "<i class='far fa-eye'></i>", "View");
                    },
                    responsivePriority: 2
                },
                {
                    data: "id",
                    sortable: false,
                    className: "actions",
                    render: (data, type, row) => {
                        return TableViews.delete_btn("/customers/" + data);
                    },
                    responsivePriority: 3
                }
            ]
        }
    }
    static gunOrdersTable(params, element) {

        const urlParams = new URLSearchParams(window.location.search);

        return {
            ordering: false,
            stateSave: false,
            columns: [
                {
                    title: "#",
                    name: "id",
                    data: "id",
                },
                {
                    title: "Name",
                    name: "customer.name",
                    filter: {
                        type: "text"
                    },
                    render: (data, type, row) => {
                        return row.customer ? (row.customer.first_name + ' ' + row.customer.last_name) : '';
                    },
                    defaultContent: '',
                },
                {

                    title: "Products",
                    data: 'product_names',
                    filter: {
                        type: "text"
                    },
                    responsivePriority: 1,
                    defaultContent: '',
                },
                {
                    title: "Serial Numbers",
                    data: 'serial_numbers',
                    filter: {
                        type: "text"
                    },
                    responsivePriority: 1,
                    defaultContent: '',
                },
                {
                    title: "Order Type",
                    data: 'order_type',
                    responsivePriority: 1,
                    defaultContent: '',
                    render: (data, type, row) => {
                        return TableViews.capitalise(data);
                    },
                },
                {
                    title: "Total",
                    data: 'total',
                    responsivePriority: 1,
                    defaultContent: '',
                    render: (data, type, row) => {
                        return TableViews.monetary(data);
                    },
                },
                {
                    title: "Status",
                    data: 'status.id',
                    name: "status.id",
                    filter: {
                        type :'statuses',
                        statusType: 'gun_order',
                        include: [1,2,3]
                    },
                    responsivePriority: 1,
                    defaultContent: '',
                    render: (data, type, row) => {
                        return row.status.label
                    },
                },
                {
                    title: "Date of Purchase",
                    data: 'date_of_purchase',
                    filter: {
                        type :'daterangepicker'
                    },
                    responsivePriority: 1,
                    defaultContent: '',
                    render: (data, type, row) => {
                        return TableViews.date(data);
                    },
                },
                {
                    data: "id",
                    className: "actions",
                    render: (data, type, row) => {
                        if (row['can'].indexOf('view') <= -1)
                            return ''

                        return TableViews.edit_btn("/gun-orders/" + data, false, "<i class='far fa-eye'></i>", "View");
                    },
                    defaultContent: '',
                },
                {
                    data: "id",
                    className: "actions",
                    render: (data, type, row) => {
                        if (row['can'].indexOf('update') <= -1)
                            return ''

                        return TableViews.edit_btn("/gun-orders/" + data + '/edit', false, "<i class='fal fa-pencil-alt'></i>", "Edit");
                    },
                    responsivePriority: 2,
                    defaultContent: '',
                }
            ]
        }
    }

    static gunOrdersAwaitingVerificationTable(params, element) {

        const urlParams = new URLSearchParams(window.location.search);

        return {
            ordering: false,
            stateSave: false,
            columns: [
                {
                    title: "#",
                    name: "id",
                    data: "id",
                },
                {
                    title: "Name",
                    name: "customer.name",
                    filter: {
                        type: "text"
                    },
                    render: (data, type, row) => {
                        return (row.customer.first_name + ' ' + row.customer.last_name)
                    },
                    defaultContent: '',
                },
                {

                    title: "Products",
                    data: 'product_names',
                    filter: {
                        type: "text"
                    },
                    responsivePriority: 1,
                    defaultContent: '',
                },
                {
                    title: "Order Type",
                    data: 'order_type',
                    responsivePriority: 1,
                    defaultContent: '',
                    render: (data, type, row) => {
                        return TableViews.capitalise(data);
                    },
                },
                {
                    title: "Total",
                    data: 'total',
                    responsivePriority: 1,
                    defaultContent: '',
                    render: (data, type, row) => {
                        return TableViews.monetary(data);
                    },
                },
                {
                    title: "Status",
                    data: 'status.id',
                    name: "status.id",
                    filter: {
                        type :'statuses',
                        statusType: 'gun_order',
                        include: [1,2,3]
                    },
                    responsivePriority: 1,
                    defaultContent: '',
                    render: (data, type, row) => {
                        return row.status.label
                    },
                },
                {
                    title: "Date of Purchase",
                    data: 'date_of_purchase',
                    filter: {
                        type :'daterangepicker'
                    },
                    responsivePriority: 1,
                    defaultContent: '',
                    render: (data, type, row) => {
                        return TableViews.date(data);
                    },
                },
                {
                    title: "Disclaimer",
                    data: 'customer.disclaimer',
                    responsivePriority: 1,
                    defaultContent: '',
                    render: (data, type, row) => {
                        if (data == null)
                            return '<i class="far fa-times-circle"></i>'
                        else
                            return '<i class="far fa-check-circle"></i>'
                    },
                },
                {
                    title: "Defence",
                    data: 'customer.defence_membership',
                    responsivePriority: 1,
                    defaultContent: '',
                    render: (data, type, row) => {
                        if (data == null && row.customer.fma_membership == null)
                            return '<i class="far fa-times-circle"></i>'
                        else
                            return '<i class="far fa-check-circle"></i>'
                    },
                },
                {
                    className: "actions",
                    data: "id",
                    render: (data, type, row) => {
                        if (row['can'].indexOf('update') <= -1)
                            return ''

                        return TableViews.edit_btn("/gun-orders/" + data + '/edit', false, "<i class='fal fa-pencil-alt'></i>", "Edit");
                    },
                    responsivePriority: 2,
                    defaultContent: '',
                },
                {
                    data: "customer.id",
                    className: "actions",
                    render: (data, type, row) => {
                        return TableViews.edit_btn("/customers/" + data + '/edit', 'editCustomerCard', "<i class=\"fas fa-user-edit\"></i>", "Customer");
                    },
                    defaultContent: '',
                    responsivePriority: 2
                }
            ]
        }
    }

    static actorsTable(params, element) {
                return {
                    columns: [
                {
                    title: "Reference",
                    data: "id",
                    name: "id",
                    sortable: true,
                    filter: {
                    type: "text"
                }
                },
                {
                    title: "Name",
                    name: "name",
                    sortable: false,
                    render: (data, type, row) => {
                        return (row.first_name+' '+row.last_name)
                    },
                    filter: {
                        type: "text"
                    },
                    responsivePriority: 1,
                    defaultContent: ''
                },
                {
                    title: "Date of Birth",
                    data: "date_of_birth",
                    name: "date_of_birth",
                    sortable: true,
                    filter: {
                        type: "daterangepicker"
                    },
                    render: data => {
                        return TableViews.date(data);
                    }
                },
                {
                    title: "Rate per Hour",
                    data: "rate",
                    name: "rate",
                    sortable: true,
                    filter: {
                        type: "text"
                    },
                    render: data => {

                        if (!data)
                            return '';

                        return TableViews.monetary(data);
                    },
                    defaultContent: ''
                },
                {
                    title: "Basic Hours",
                    data: "basic_hours",
                    name: "basic_hours",
                    sortable: true,
                    filter: {
                        type: "time"
                    },
                    render: data => {

                        if (!data)
                            return '';

                        return data.time;
                    }
                },
                {
                    title: "Created At",
                    data: "created_at",
                    name: "created_at",
                    sortable: true,
                    filter: {
                        type: "daterangepicker"
                    },
                    render: data => {
                        return TableViews.date_time(data)
                    }
                },
                {
                    title: "Updated At",
                    data: "updated_at",
                    name: "updated_at",
                    sortable: true,
                    filter: {
                        type: "daterangepicker"
                    },
                    render: data => {
                        return TableViews.date_time(data)
                    }
                },
                {
                    data: "id",
                    sortable: false,
                    className: "actions",
                    render: (data, type, row) => {
                        return TableViews.edit_btn("/actors/" + data + "/edit", false, "<i class='fal fa-pencil-alt'></i>", "Edit");
                    },
                    responsivePriority: 2
                },
                {
                    data: "id",
                    sortable: false,
                    className: "actions",
                    render: (data, type, row) => {
                        return TableViews.delete_btn("/actors/" + data);
                    },
                    responsivePriority: 3
                }
            ]
        }
    }

    static gunServicesTable(params, element) {

        return {
            ordering: false,
            stateSave: false,
            columns: [
                {
                    title: "#",
                    name: "id",
                    data: "id",
                },
                {
                    title: "Name",
                    name: "customer.name",
                    filter: {
                        type: "text"
                    },
                    render: (data, type, row) => {
                        return (row.customer.first_name + ' ' + row.customer.last_name)
                    },
                    defaultContent: '',
                },
                {
                    title: "Mobile",
                    data: "customer.mobilephone.value",
                    name: "customer.mobilephone",
                    sortable: false,
                    filter: {
                        type: "text"
                    },
                    defaultContent: '',
                },
                {
                    title: "Gun",
                    data: 'gun',
                    filter: {
                        type: "text"
                    },
                    responsivePriority: 1,
                    defaultContent: '',
                },
                {
                    title: "Serial Number",
                    data: 'serial_number',
                    filter: {
                        type: "text"
                    },
                    responsivePriority: 1,
                    defaultContent: '',
                },
                {
                    title: "Total",
                    data: 'total',
                    responsivePriority: 1,
                    defaultContent: '',
                    render: (data, type, row) => {
                        return TableViews.monetary(data);
                    },
                },
                {
                    title: "Status",
                    data: 'status.id',
                    name: "status.id",
                    filter: {
                        type :'statuses',
                        statusType: 'gun_service'
                    },
                    responsivePriority: 1,
                    defaultContent: '',
                    render: (data, type, row) => {
                        return row.status.label
                    },
                },
                {
                    title: "Date of Service",
                    data: 'date_of_service',
                    filter: {
                        type :'daterangepicker'
                    },
                    responsivePriority: 1,
                    defaultContent: '',
                    render: (data, type, row) => {
                        return TableViews.date(data);
                    },
                },
                {
                    data: "id",
                    className: "actions",
                    render: (data, type, row) => {
                        if (row['can'].indexOf('view') <= -1)
                            return ''

                        return TableViews.edit_btn("/gun-services/" + data, false, "<i class='far fa-eye'></i>", "View");
                    },
                    defaultContent: '',
                },
                {
                    data: "id",
                    className: "actions",
                    render: (data, type, row) => {
                        if (row['can'].indexOf('edit') <= -1)
                            return ''

                        return TableViews.edit_btn("/gun-services/" + data + '/edit', false, "<i class='fal fa-pencil-alt'></i>", "Edit");
                    },
                    defaultContent: '',
                    responsivePriority: 2
                }
            ]
        }
    }

    static actorTimesheetsTable(params, element) {
        return {
            order: [[0, 'desc']],
            columns: [
                {
                    title: "Reference",
                    data: "id",
                    name: "id",
                    sortable: true,
                    filter: {
                        type: "text"
                    }
                },
                {
                    title: "Actor Name",
                    data: "actor.name",
                    name: "actor_name",
                    sortable: true,
                    filter: {
                        type: "text"
                    },
                    render: (data, type, row) => {
                        return (row.actor.first_name + ' ' + row.actor.last_name)
                    },
                    responsivePriority: 1,
                    defaultContent: ''
                },
                {
                    title: "Rate per Hour",
                    data: "rate",
                    name: "rate",
                    sortable: false,
                    render: (data, type, row) => {
                        return TableViews.hide_show(`rate-${row.id}`, TableViews.monetary(data));
                    }
                },
                {
                    title: "Hours",
                    data: "hours",
                    name: "hours",
                    sortable: true,
                    render: data => {
                        return data.time;
                    }
                },
                {
                    title: "Make Up Hours",
                    data: "make_up_time",
                    name: "make_up_time",
                    sortable: true,
                    render: data => {
                        return data.time;
                    }
                },
                {
                    title: "Deductions",
                    data: "deduction_time",
                    name: "deduction_time",
                    sortable: true,
                    render: data => {
                        return data.time;
                    }
                },
                {
                    title: "Status",
                    name: "disclaimers",
                    data: 'disclaimers',
                    sortable: false,
                    filter: {
                        type: 'timesheet_statuses'
                    },
                    render: (data, type, row) => {
                        if (row.disclaimers.length == 1) {
                            return 'On Hire';
                        }
                        if (row.disclaimers.length == 2) {
                            return 'Returned';
                        }
                        return '';
                    }
                },
                {
                    title: "Created At",
                    data: "created_at",
                    name: "created_at",
                    sortable: true,
                    filter: {
                        type: "daterangepicker"
                    },
                    render: data => {
                        return TableViews.date_time(data)
                    }
                },
                {
                    title: "Updated At",
                    data: "updated_at",
                    name: "updated_at",
                    sortable: true,
                    filter: {
                        type: "daterangepicker"
                    },
                    render: data => {
                        return TableViews.date_time(data)
                    }
                },
                {
                    data: "id",
                    sortable: false,
                    className: "actions",
                    render: (data, type, row) => {
                        if (row.disclaimers.length == 1) {
                            return TableViews.edit_btn(`/actor-timesheets/${row.id}/sign-in`, 'signInTimesheetCard', "Sign In", "Edit");
                        }
                        else {
                            return "";
                        }
                    },
                    responsivePriority: 2
                },
                {
                    data: "id",
                    sortable: false,
                    className: "actions",
                    render: (data, type, row) => {
                        return TableViews.edit_btn("/actor-timesheets/" + data + "/edit", false, "<i class='fal fa-pencil-alt'></i>", "Edit");
                    },
                    responsivePriority: 2
                },
                {
                    data: "id",
                    sortable: false,
                    className: "actions",
                    render: (data, type, row) => {
                        return TableViews.delete_btn("/actor-timesheets/" + data);
                    },
                    responsivePriority: 3
                }
            ]
        }
    }
    static disclaimerTypesTable(params, element) {

        return {
            ordering: false,
            columns: [
                {
                    title: "Type",
                    data: "name",
                    name: "type",
                    responsivePriority: 1
                },
                {
                    title: "Content",
                    data: "content",
                    name: "content",
                    render: (data, type, row) => {
                        return TableViews.wysiwyg(data)
                    }
                },
                {
                    data: "id",
                    sortable: false,
                    className: "actions",
                    render: (data, type, row) => {
                        return TableViews.edit_btn("/settings/disclaimer-types/" + data + "/edit", false, "<i class='fal fa-pencil-alt'></i>", "Edit");
                    },
                    responsivePriority: 2
                },
            ]
        }
    }
    static servicesTable(params, element) {

        return {
            ordering: false,
            columns: [
                {
                    title: "Name",
                    data: "name",
                    name: "type",
                    responsivePriority: 1
                },
                {
                    title: "Price",
                    data: "price",
                    name: "price",
                    render: (data, type, row) => {
                        return `£${data}`
                    }
                },
                {
                    data: "id",
                    sortable: false,
                    className: "actions",
                    render: (data, type, row) => {
                        return TableViews.edit_btn("/settings/services/" + data + "/edit", false, "<i class='fal fa-pencil-alt'></i>", "Edit");
                    },
                    responsivePriority: 2
                },
            ]
        }
    }
}
