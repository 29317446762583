import {TableParams} from "./TableParams";
import {Components} from "../../components";
import {TableOnDraw} from "./TableOnDraw";

export class Tables {

    /**
     * Finds each table in the given selector and initialises DataTables on this
     * @param selector
     */
    static init(selector) {
        if($.fn.dataTable === undefined) {
            require('datatables.net-bs4');
            require('datatables.net-responsive-bs4');
        }

        $('table[data-init]', selector).each((key, item) => {
            this.initDataTable(item);
        });
    }

    /**
     * Initialises DataTables on the specified element.
     *
     * @param element
     */
    static initDataTable(element) {
        let $element = $(element),
            table = undefined,
            params = {
                orderCellsTop: true,
                stateSave: false,
                fixedHeader: true,
                responsive: {
                    details: {
                        renderer: function ( api, rowIdx, columns ) {
                            var data = $.map( columns, function ( col, i ) {
                                return col.hidden ?
                                    '<tr data-dt-row="'+col.rowIndex+'" data-dt-column="'+col.columnIndex+'">'+
                                    '<td>'+col.title+':'+'</td> '+
                                    '<td>'+$('<textarea />').html(col.data).text()+'</td>'+
                                    '</tr>' :
                                    '';
                            } ).join('');

                            return data ?
                                $('<table/>').append( data ) :
                                false;
                        }
                    }
                },
                processing: true,
                drawCallback: function( settings ) {

                    if($element.data('on-draw') !== undefined)
                        TableOnDraw[$element.data('on-draw')](settings, settings.nTable, settings.oInstance.api());

                    new Components($element, false);
                },
                dom: 'rtip',
            };

        //Loading the data from the source defined on the table element
        if($element.data('src') !== undefined) {
            params = Object.assign(params, {
                autoWidth: false,
                serverSide: true,
                ajax: {
                    url: $element.data('src'),
                    type: "POST",
                    data: {
                        _token: window.token.content,
                    }
                }
            });
        }

        //Loading the specific parameters for this table
        if($element.data('init') !== undefined)
            params = Object.assign(params, TableParams[$element.data('init')](params, element));

        table = $element.dataTable(params);

        table.on('responsive-resize.dt', function ( e, datatable, columns ) {
            Tables.drawFilters($element, table);
        });

        this.drawFilters(element, table);
    }

    static reloadDataTables(selector) {
        $(selector).find('table').each(function () {
            let api = new $.fn.dataTable.Api(this);

            api.ajax.reload();
        });
    }

    static drawFilters(element, datatable) {
        let hasFilters = false;

        $('thead tr.filter-row', element).remove();
        $('thead tr', element).clone(true).addClass('filter-row').appendTo( $('thead', element) );

        $('thead tr:eq(1) th', element).each( function (i) {
            let title = $(this).text(),
                $html = '';

            //Determining which column in the datatables config this represents
            let index = 0;
            $.each(datatable.api().columns().responsiveHidden(), function (key, value) {

                if(value === true && index === i) {
                    index = key;
                    return false;
                } else if(value === true) {
                    ++index;
                }
            });


            let definition = datatable.api().settings()[0].aoColumns[i];

            if(definition.filter === undefined || definition.filter === false || datatable.api().column(index).responsiveHidden() === false) {
                $(this).html('');
                return true;
            }

            hasFilters = true;

            switch(definition.filter.type) {
                case 'datetimerangepicker':
                    $html = $('<input type="text" data-date-range="datetimerangepicker" class="form-control">')
                        .val(datatable.api().column(index).search())
                        .addClass('filter-' + definition.name)
                        .attr('placeholder', 'Filter by ' + title.toLowerCase());
                    break;
                case 'daterangepicker':
                    $html = $('<input type="text" data-date-range="daterangepicker" class="form-control">')
                        .val(datatable.api().column(index).search())
                        .addClass('filter-' + definition.name)
                        .attr('placeholder', 'Filter by ' + title.toLowerCase());
                    break;
                case 'datepicker':
                    $html = $('<input type="text" data-date-range="datepicker" class="form-control">')
                        .val(datatable.api().column(index).search())
                        .addClass('filter-' + definition.name)
                        .attr('placeholder', 'Filter by ' + title.toLowerCase());
                    break;
                case 'statuses':
                    const urlParams = new URLSearchParams(window.location.search), filterVal = datatable.api().column(index).search()

                    let params = {'type': definition.filter.statusType, include: definition.filter.include}

                    $html = $('<select class="form-control">')
                        .data('selected', urlParams.has('status') ? urlParams.get('status') : filterVal)
                        .data('src', '/api/statuses?'+$.param(params))
                        .data('srcValue', 'id')
                        .data('srcLabel', 'label')
                        .addClass('filter-' + definition.name)
                        .attr('placeholder', 'Filter by ' + title.toLowerCase());

                    if (urlParams.has('status')) {
                        datatable.api().column(index).search( urlParams.get('status') ).draw()
                    }
                    break;
                case 'monetary':
                    $html = $('<input type="monetary" class="form-control">')
                        .val(datatable.api().column(index).search())
                        .addClass('filter-' + definition.name)
                        .attr('placeholder', 'Filter by ' + title.toLowerCase());
                    break;
                case 'time':
                    $html = $('<input type="text" data-mask="time" class="form-control">')
                        .val(datatable.api().column(index).search())
                        .addClass('filter-' + definition.name)
                        .attr('placeholder', 'Filter by ' + title.toLowerCase());
                    break;
                case 'costume_hires_status':
                    $html = $('<select class="form-control">' +
                        '<option selected></option>' +
                        '<option value="On Hire">On Hire</option>' +
                        '<option value="Returned">Returned</option>' +
                        '</select>')
                        .addClass('filter-' + definition.name)
                        .attr('placeholder', 'Filter by Status');
                    break;
                case 'epos_now_statuses':
                    $html = $('<select class="form-control">' +
                        '<option selected></option>' +
                        '<option value="1">Linked</option>' +
                        '<option value="0">Not Linked</option>' +
                        '</select>')
                        .addClass('filter-' + definition.name)
                        .attr('placeholder', 'Filter by Status');
                    break;
                case 'timesheet_statuses':
                    $html = $('<select class="form-control">' +
                        '<option selected></option>' +
                        '<option value="on_hire">On Hire</option>' +
                        '<option value="returned">Returned</option>' +
                        '</select>')
                        .addClass('filter-' + definition.name)
                        .attr('placeholder', 'Filter by Status');
                    break;
                default:
                    $html = $('<input type="' + definition.filter.type + '" class="form-control">')
                        .val(datatable.api().column(index).search())
                        .addClass('filter-' + definition.name)
                        .attr('placeholder', 'Filter by ' + title.toLowerCase());
                    break;
            }

            $(this).html( $html );

            new Components($(this), false)

            let timer;
            $( 'input, select', this ).on( 'keyup change', function () {
                if ( datatable.api().column(index).search() !== this.value ) {
                    if ($(this).is("input")) {

                        let value = this.value
                        clearTimeout(timer)

                        timer = setTimeout(function() {
                            searchColumn(index, value)
                        }, 1000);

                    } else {
                        searchColumn(index, this.value)
                    }
                }
            } );
        }).off('click').removeClass('sorting sorting_desc sorting_asc');

        function searchColumn(index, value) {
            datatable.api()
                .column(index)
                .search( value )
                .draw();
        }

        if(!hasFilters)
            $('thead tr.filter-row', element).remove();
    }


}
