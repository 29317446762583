export default class Wysiwyg {

    static wysiwyg(selector) {
        require('trumbowyg/dist/trumbowyg');

        $(selector).find('textarea.wysiwyg:not(.wysiwyg-template)').each((key, item) => {
            let is_disabled = $(item).closest('fieldset:disabled');

            let options = {
                prefix: 'smeditor-',
                btns: [
                    ['undo', 'redo'], // Only supported in Blink browsers
                    ['formatting'],
                    ['strong', 'em', 'del'],
                    ['link'],
                    ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
                    ['unorderedList', 'orderedList'],
                    ['horizontalRule'],
                ],
                disabled: is_disabled.length > 0,
                plugins: {}
            };

            $(item).trumbowyg(options);

        });
    }
}
