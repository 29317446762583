import SignaturePad from "signature_pad";

export default class Signature {
    static init (selector) {
        $(selector).find('.signature-pad').each( function () {

            let $element = $(this), $input = $element.parents('.form-group').find('input');


            let options = {
                backgroundColor: 'rgba(255, 255, 255, 0)',
                penColor: 'rgb(0, 0, 0)',
            };


            var signaturePad = new SignaturePad($element[0], options);


            if($input.attr('name')){
                signaturePad.fromDataURL($input.val());
            }

            $(selector).find('.signature-pad-clear').click( function () {
                signaturePad.clear();
                $input.val('');
            });

            $element.off('mouseup touchend').on('mouseup touchend', function (e) {
                let value;

                if(signaturePad.isEmpty())
                    value = null;
                else
                    value = signaturePad.toDataURL();



                $input.val(value);
            })

        })
    }
}
