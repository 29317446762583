import helpers from "../helpers";

export default class GunServiceTotal {

    static init(selector) {
        this.gunServiceChanges(selector)
        this.removedGunServiceItem(selector)

        this.quantityChange(selector)
    }

    /**
     * Post to total endpoint and handle the response.
     * Set the service line totals
     * Set the overall total
     *
     * @param params
     */
    static calculate(params) {
        axios
            .post('/api/gun-services/total', params)
            .then(function (response) {

                const serviceLines = GunServiceTotal.getServiceLines();

                response.data.serviceLines.forEach(function (data, index) {
                    const $serviceLine = $(serviceLines[index])

                    //Pick the keys which are required
                    const subset = (({price, tax, subtotal, total}) => ({
                        price,
                        tax,
                        subtotal,
                        total,
                    }))(data)

                    helpers.setElementContent('.', subset, $serviceLine)
                })

                helpers.setElementContent('#', response.data.overallTotal)

            });
    }

    /**
     * Capture all changes which result in the totals needing to be re-calculated
     *
     * @param selector
     */
    static gunServiceChanges(selector) {

        $('[service-variable]', GunServiceTotal.getServiceLines()).add($('#services')).unbind("change").bind("change", function () {
            GunServiceTotal.calculate(GunServiceTotal.getServiceData())
        });
    }

    /**
     * Get all service data and return
     *
     */
    static getServiceData() {
        let APIParams = {}
        APIParams.serviceLines = []

        this.getServiceLines().each((index, element) => {
            APIParams.serviceLines.push(this.getServiceLineData($(element)))
        });

        APIParams.services = $("#services").val()

        return APIParams;

    }

    /**
     * Gets the service line data for a single row
     *
     * @param $element
     */
    static getServiceLineData($element) {
        return {
            'woocommerce_id': $('[name*="woocommerce_id"]', $element).val(),
            'epos_now_id': $('[name*="epos_now_id"]', $element).val(),
            'price': $('[name*="price"]', $element).inputmask('unmaskedvalue'),
            'labour_cost': $('[name*="labour_cost"]', $element).inputmask('unmaskedvalue'),
            'name': $('[name*="name"]', $element).val(),
            'tax': $('[name*="tax"]', $element).inputmask('unmaskedvalue'),
            'quantity': $('[name*="quantity"]', $element).val(),
        }
    }

    /**
     * Return all the service line items
     */
    static getServiceLines() {
        return $('[data-repeater-list="lines"] [data-repeater-item]:visible');
    }

    static quantityChange(selector) {
        $('[name*="quantity"]', GunServiceTotal.getServiceLines()).unbind("change").bind("change", function (event) {
            let $element = $(event.currentTarget), $parentTr = $element.parents('tr')

            //Divide by the previous value and times by the new quantity
            $('[name*="price"]', $parentTr).val(($('[name*="price"]', $parentTr).inputmask('unmaskedvalue') / $element.prop('defaultValue')) * $element.val())

            $('[name*="labour_cost"]', $parentTr).val(($('[name*="labour_cost"]', $parentTr).inputmask('unmaskedvalue') / $element.prop('defaultValue')) * $element.val())
                //Trigger change to re-calc totals
                .change()

            //Set new default value to the current value
            $element.prop('defaultValue', $element.val())

        });
    }

    static removedGunServiceItem(selector) {
        $('#service_guns').unbind("rowRemoved").bind("rowRemoved", function (event) {
            GunServiceTotal.calculate(GunServiceTotal.getServiceData())
        })
    }
}
