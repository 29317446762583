export class DateRangePicker {

    static init (selector) {

        $('input[data-date-range="datetimerangepicker"]', selector).each((key, item) => {
            DateRangePicker.dateTimeRangePicker(item);
        })
        $('input[data-date-range="daterangepicker"]', selector).each((key, item) => {
            DateRangePicker.dateRangePicker(item);
        })

        $('input[data-date-range="datepicker"]', selector).each((key, item) => {
            DateRangePicker.single_picker(item, $(item).is('[data-time]'));
        })
    }

    static dateTimeRangePicker(element) {
        if($.fn.daterangepicker === undefined)
            require('daterangepicker');

        $(element).daterangepicker({
            startDate: moment().startOf('day'),
            endDate: moment().endOf('day'),
            autoUpdateInput: false,
            timePicker: true,
            showDropdowns: true,
            alwaysShowCalendars: true,
            locale: {
                format: 'DD/MM/YYYY hh:mm A',
                cancelLabel: 'Clear',
                firstDay: 1
            },
            ranges: {
                'Today': [moment().startOf('day'), moment().endOf('day')],
                'Yesterday': [moment().startOf('day').subtract(1, 'days'), moment().endOf('day').subtract(1, 'days')],
                'Last 7 Days': [moment().startOf('day').subtract(6, 'days'), moment().endOf('day')],
                'Last 30 Days': [moment().startOf('day').subtract(29, 'days'), moment().endOf('day')],
                'This Month': [moment().startOf('day').startOf('month'), moment().endOf('day').endOf('month')],
                'Last Month': [moment().startOf('day').subtract(1, 'month').startOf('month').endOf('day'), moment().subtract(1, 'month').endOf('month')]
            }
        }).off('apply.daterangepicker').on('apply.daterangepicker', (e, picker) => {
            $(e.target).val(picker.startDate.format(picker.locale.format) + ' - ' + picker.endDate.format(picker.locale.format)).trigger('change');
        }).off('cancel.daterangepicker').on('cancel.daterangepicker', (e, picker) => {
            $(e.target).val('').trigger('change');
        });
    }
    static dateRangePicker(element) {
        if($.fn.daterangepicker === undefined)
            require('daterangepicker');

        $(element).daterangepicker({
            startDate: moment().startOf('day'),
            endDate: moment().endOf('day'),
            autoUpdateInput: false,
            timePicker: false,
            showDropdowns: true,
            alwaysShowCalendars: true,
            locale: {
                format: 'DD/MM/YYYY',
                cancelLabel: 'Clear',
                firstDay: 1
            },
            ranges: {
                'Today': [moment().startOf('day'), moment().endOf('day')],
                'Yesterday': [moment().startOf('day').subtract(1, 'days'), moment().endOf('day').subtract(1, 'days')],
                'Last 7 Days': [moment().startOf('day').subtract(6, 'days'), moment().endOf('day')],
                'Last 30 Days': [moment().startOf('day').subtract(29, 'days'), moment().endOf('day')],
                'This Month': [moment().startOf('day').startOf('month'), moment().endOf('day').endOf('month')],
                'Last Month': [moment().startOf('day').subtract(1, 'month').startOf('month').endOf('day'), moment().subtract(1, 'month').endOf('month')]
            }
        }).off('apply.daterangepicker').on('apply.daterangepicker', (e, picker) => {
            $(e.target).val(picker.startDate.format(picker.locale.format) + ' - ' + picker.endDate.format(picker.locale.format)).trigger('change');
        }).off('cancel.daterangepicker').on('cancel.daterangepicker', (e, picker) => {
            $(e.target).val('').trigger('change');
        });
    }

    static single_picker(element, time = false) {
        if($.fn.daterangepicker === undefined)
            require('daterangepicker');


        let value = $(element).val(),
            startDate = value.length > 0 ? moment(value, "DD/MM/YYYY") : moment();

        let dateOptions = {
            singleDatePicker: true,
            startDate: startDate,
            autoUpdateInput: false,
            showDropdowns: true,
            timePicker: time,
            locale: {
                format: 'DD/MM/YYYY' + (time ? ' HH:mm' : ''),
                cancelLabel: 'Clear',
                firstDay: 1
            },
            drops: 'auto',
            autoApply: true
        }

        if(typeof $(element).data('min-date') !== "undefined")
            dateOptions.minDate = $(element).data('min-date')

        if(typeof $(element).data('max-date') !== "undefined")
            dateOptions.maxDate = $(element).data('max-date')


        $(element).daterangepicker(dateOptions).off('apply.daterangepicker').on('apply.daterangepicker', (e, picker) => {
            $(e.target).val(picker.startDate.format(picker.locale.format)).trigger('change');
        }).off('cancel.daterangepicker').on('cancel.daterangepicker', (e, picker) => {
            $(e.target).val('').trigger('change');
        });
    }
}
