const GunOrderMiscProduct = (selector) => {



    if($(selector).is('[data-repeater-item]') || !$('#gunOrderFormContainer', selector).length)
        return true;

    let $repeat = $('[repeater]', selector), repeaterApi = $repeat[0].repeater
    $('.miscButton', selector).on('click', () => {
        let $template = repeaterApi.getTemplate().clone()

        $('.productNameSelect', $template).remove()
        $('input[name*="name"]', $template).prop('type', 'text').show()
        $('.productType', $template).val('misc_product')

        repeaterApi.addItem([], false, $template)
    })
}

export default GunOrderMiscProduct
