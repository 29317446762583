export class Alerts {

    static init() {

        let flash_message = $('.toast-container > .toast-deck').data('flash');

        if(flash_message !== undefined && flash_message.length > 0)
            this.success(flash_message);

        $('.toast-container > .toast-deck').removeAttr('data-flash');
    }

    static success(message, options) {
        this.toast(message, "success", options);
    }

    static error(message) {
        this.toast(message, "danger");
    }

    static toast(content, type, options) {
        if(options === undefined)
            options = {};

        let $element = $('<div class="toast toast-' + type + '" role="alert" aria-live="assertive" aria-atomic="true">' +
            '<div class="toast-header">' +
            '   <strong class="mr-auto">Urban</strong>' +
            '   <small class="text-muted time-difference">Just now</small>' +
            '   <button type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">' +
            '      <span aria-hidden="true">&times;</span>' +
            '   </button>' +
            '</div>' +
            '<div class="toast-body">' + content + '</div>' +
            '</div>'),
            date = moment();

        if(options.clipboard !== undefined && options.clipboard.length > 0) {
            $('.toast-body', $element).append(
                $('<span class="copy-clipboard">').text(options.clipboard)
            )
        }

        $element.toast({
            delay: 7500
        });

        $('.toast-container > .toast-deck').append(
            $element.toast('show')
        );

        function updateHumanize() {
            $('.time-difference', $element).text(date.fromNow());

            setTimeout(function () {
                updateHumanize()
            }, 750);
        }

        updateHumanize();
    }
}
