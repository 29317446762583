export default class helpers {

    /**
     * Set element value or text
     * @param prefix
     * @param data
     * @param element
     */
    static setElementContent(prefix, data, element = 'body') {

        for (const [key, value] of Object.entries(data)) {

            if ($(prefix + key, $(element)).is(':input')) {

                $(prefix + key, $(element)).val(value)

            } else {

                $(prefix + key, $(element)).text(value)

            }

        }
    }
}
