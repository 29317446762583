export class FormOnSuccess {
    /**
     * After disclaimer is created, assign to signature input
     * @param form
     * @param responseData
     */
    static disclaimer(form, responseData) {

        $(':input[name="signature_id"]').val(responseData['session']['signature_id']);
        $('.disclaimerLinks').remove();
        $('[data-element="customersDisclaimerCard"]').remove();
        $('[data-element="costumeHiresDisclaimerCard"]').remove();
        $('.success-disclaimer').show();

    }



    /**
     * Set the verification status once a customer has been updated on a gun order
     *
     * @param form
     * @param responseData
     */
    static customerUpdatedGunOrder(form, responseData) {
        if (responseData.session.customer.verified) {
            $('#customer-verification').removeClass('unverified').addClass('verified').text('Verified')
        } else {
            $('#customer-verification').removeClass('verified').addClass('unverified').text('Unverified')
        }
    }

    /**
     * Add the new customer to the customer select
     *
     * @param form
     * @param data
     */
    static customerCreateSelectize(form, data)
    {
        let sApi = $('#customer_id')[0].selectize;

        let mockSearchable = {
            searchable: data.session.customer,
            title: data.session.customer.first_name + ' ' + data.session.customer.last_name,
            id: data.session.customer.id
        }

        sApi.addOption(mockSearchable);
        sApi.addItem(mockSearchable.id);
        sApi.setValue(mockSearchable.id);
        $('#overlay').remove()
    }

    /**
     * Add the new customer to the customer select
     *
     * @param form
     * @param data
     */
    static actorCreateSelectize(form, data)
    {
        let sApi = $('#inputActor')[0].selectize;

        let mockSearchable = {
            searchable: data.session.actor,
            title: data.session.actor.first_name + ' ' + data.session.actor.last_name,
            id: data.session.actor.id
        }

        sApi.addOption(mockSearchable);
        sApi.addItem(mockSearchable.id);
        sApi.setValue(mockSearchable.id);
        $('#overlay').remove()
    }
}
