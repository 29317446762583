const GunServiceMiscProduct = (selector) => {

    if($(selector).is('[data-repeater-item]') || !$('#gunServiceFormContainer', selector).length)
        return true;

    let $repeat = $('[repeater]', selector), repeaterApi = $repeat[0].repeater
    $('.miscButton', selector).on('click', () => {
        let $template = repeaterApi.getTemplate().clone()

        $('.productNameSelect', $template).remove()
        $('.miscName', $template).prop('type', 'text').prop('disabled', false).show()
        $('.productType', $template).val('misc_product')

        repeaterApi.addItem([], false, $template)
    })
}

export default GunServiceMiscProduct
