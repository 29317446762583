import {TableViews} from "./Tables/TableViews";

export default class Customer {

    static setCustomerOrderInformation($container, data) {
        const membership = data.f_m_a_membership || data.defence_membership || null;

        $('.customerPostcode span').text(data.address.postcode);
        $('.customerDefenceNumber span').text(membership ? membership.value : '');
        $('.customerDefenceExpiry span').text(membership ? TableViews.date(membership.expiry) : '');

        if (data.photo) {
            $('.noImage', $container).hide()
            $('.customerImage', $container).attr('src', data.photo.url).show();

        } else {
            $('.customerImage').attr('src', '').hide()
            $('.noImage', $container).show()
        }

    }

    static resetCustomerOrderInformation ($container) {
        $('.customerPostcode span, .customerDefenceNumber span, .customerDefenceExpiry span,' +
            '.customerFMANumber span, .customerFMAExpiry span', $container).text('')

        $('.customerImage').attr('src', '')
    }
}
