import {Components} from "../components";
import 'js-loading-overlay';

export default class Repeater {

    static JRepeater(selector) {
        let initFunction;

        require('../lawrenceRepeater');


        if($(selector).is('[repeater]')){
            if ((initFunction = $(selector).data('init')) !== undefined){
                Repeater[initFunction]($(selector))
            }
            else{
                Repeater.initJRepeater($(selector))
            }
        }

        $('[repeater]', selector).each((key, element) => {
            if ((initFunction = $(element).data('init')) !== undefined){
                Repeater[initFunction]($(element))
            }
            else{
                Repeater.initJRepeater($(element))
            }
        });
    }

    static initJRepeater(selector) {
        let $element = $(selector);

        let options = {

            initEmpty: false,
            show: function () {
                showItem($(this));
            },
            hide: function (deleteElement) {
                hideItem($(this), deleteElement)
            },
            ready: function (setIndexes) {

            },
            repeaters: [],

            isFirstItemUndeletable: false,
        };

        $('[inner-repeater]', selector).each(function (index, element) {
            $(element).addClass('innerRepeater'+index);

            options.repeaters.push({
                selector: '.innerRepeater'+index,
                show: function () {
                    showItem($(this));

                },
                hide: function (element) {
                    hideItem($(element))
                }
            })
        });


        $element.repeater(options);

        function showItem($repeaterItem) {

            $repeaterItem.slideDown();

            $('select.selectize-template', $repeaterItem).each( function (index, element) {
                if($(element).closest('[data-repeater-item]')[0] == $repeaterItem[0])
                    $(element).removeClass('selectize-template')
            })

            $repeaterItem.find('textarea.wysiwyg-template').removeClass('wysiwyg-template')

            $repeaterItem.find(':input[type="file"].filepond-template').removeClass('filepond-template')


            /** adds components js to the new repeater item */
            new Components($repeaterItem, false);
        }

        function hideItem($element, deleteElement) {

            if(confirm('Are you sure you want to delete this element?')) {
                $element.slideUp(deleteElement);
                $element.remove();
            }
        }
    }
}
