export default class CustomerSearch {

    static init(selector)
    {
        $('.searchCustomerById, .searchCustomerByEmail', selector).each( function (index, container) {
            CustomerSearch.search(container)
        })

        CustomerSearch.initCheckBox(selector)
    }

    static search(container)
    {
        const $searchButton = $('[role="button"]', container), $input = $(':input', container)

        $searchButton.on('click', function (e) {
            if($input.val() === '')
                return true

            CustomerSearch[$(container).data('search')](container, $input)
        });
    }

    static searchCustomerById(container, input)
    {
        axios.get('/api/epos-now/customers', {
                params: {
                    customer_id: $(input).val(),
                }
            }
        ).then(({ data }) => {

            if(!data.hasOwnProperty('success') || (data.hasOwnProperty('success') && !data.success)) {
                CustomerSearch.resetCustomerFields(data.message);
                return true
            }

            CustomerSearch.handleFoundCustomer(data.body, input)

        })
    }

    static searchCustomerByEmail(container, input)
    {
        axios.get('/api/epos-now/customers', {
                params: {
                    customer_email: $(input).val(),
                }
            }
        ).then(({ data }) => {

            if(!data.hasOwnProperty('success') || (data.hasOwnProperty('success') && !data.success)) {
                CustomerSearch.resetCustomerFields(data.message);
                return true
            }

            CustomerSearch.handleFoundCustomer(data.body, input)

        })
    }

    static setFields(data = {}) {

        $('[name="epos_now_id"]').val(data.Id ?? '')
        $('.eposNowId span').text(data.Id ?? '')
        $('.eposName span').text((data.Forename ?? '') + ' ' + (data.Surname ?? ''))
        $('.eposEmail span').text(data.EmailAddress ?? '')


    }
    static setFoundCustomerFields(data = {}) {

        CustomerSearch.setFields(data)
        $('.customerSearchMessage').text('Customer Has Been Found')
        $('.customerDetails').show()
    }

    static resetCustomerFields(message = 'No Customers Have Been found')
    {
        CustomerSearch.setFields();
        $('.customerSearchMessage').html(message)
        $('.customerDetails').hide()
    }


    static handleFoundCustomer(matchedCustomer, input)
    {
        CustomerSearch.resetInputs(input)
        CustomerSearch.setFoundCustomerFields(matchedCustomer)
    }

    static resetInputs(input)
    {
        const $inputs = $('[name="epos_now_create"], [name="epos_now_search_email"], [name="epos_now_search_id"]')


        $inputs.not(input).each(function () {
            if($(this).is(":checkbox")) {
                $(this).prop('checked', false)
            } else {
                $(this).val('')
            }
        })
    }

    static initCheckBox(selector)
    {
        const $checkbox = $('[name="epos_now_create"]', selector);

        $checkbox.change(function() {
            $('.customerSearchMessage').empty()
            $('.customerDetails').hide()
            CustomerSearch.setFields();
            CustomerSearch.resetInputs($checkbox)
        })
    }
}
