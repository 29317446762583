import {Components} from "../../components";
import {Alerts} from "../Alerts";
import {FormOnSubmit} from "./FormOnSubmit";
import {FormOnSuccess} from "./FormOnSuccess";
import {FormOnError} from "./FormOnError";
import {Modals} from "../Modals/Modals";
import {Tables} from "../Tables/Tables";
import Utilities from "../Utilities";
import FormConfirmation from "./FormConfirmation";
import {JsLoader} from "../JsLoader";

export class Forms {

    /**
     * Attaches AJAX functionality to any form element within the given selector.
     *
     * @param selector
     */
    static ajaxSubmit(selector) {

        $(selector).find('form:not([no-ajax])').off('submit').on('submit', (e) => {

            e.preventDefault();

            let form = $(e.target)

            if (form.is('[data-confirmation]')) {
                FormConfirmation[$(form).data('confirmation')](form)
            } else {
                this.submit(form);
            }


        })
    }

    static submit(form, callback) {

        JsLoader.show()

        let data = new FormData(form[0]),
            elements = this.getRenderElements(form);

        // if(!form.is('[data-silent]'))
        //     Forms.formLoader(form);

        if (form.is('[data-on-submit]'))
            data = FormOnSubmit[form.data('onSubmit')](form, data);


        //Check whether we have any monetary inputs
        if(form.find('input[data-date-range="datepicker"]').length) {
            form.find('input[data-date-range="datepicker"]').each((key, element) => {

                let date = moment($(element).val(), 'DD/MM/YYYY' + ($(element).is('[data-time]') ? 'HH:mm' : ''));
                if(date.isValid())
                    data.set($(element).attr('name'), date.format("YYYY-MM-DD" + ($(element).is('[data-time]') ? ' HH:mm' : '')));

            });
        }

        //Remove mask for input masks when removeMaskOnSubmit is selected
        $('[inputmode]', form).each( (index, element) => {
            if($(element).inputmask("option", 'removeMaskOnSubmit'))
                data.set($(element).attr('name'), $(element).inputmask('unmaskedvalue'))
        });

        //Check whether we have any repeater elements in this form
        if(form.find('[data-repeater-list]').length) {
            form.find('[data-repeater-list] [data-repeater-item]:first-child').each((key, element) => {
                $(':input, select', element).each((key, input) => {
                    data.delete($(input).attr('name'));
                });
            });
        }

        //Check if file location has download in url
        if(form.find('.filepond--data [type="hidden"][name*="photo"]').length) {

            form.find('.filepond--data [type="hidden"][name*="photo"]').each((index, element) => {

                if($(element).val().includes('/file-download/'))
                    data.set(
                        ($(element).attr('name').includes('[]') ? $(element).attr('name').replace('[]', '['+ $(element).index() +']')  : $(element).attr('name')),
                        $(element).val().replace('/file-download/', ''));
            });
        }

        form.find('[enableOnSubmit="true"]').each((index, element) => {
            data.set($(element).attr('name'), $(element).val());
        });

        if(form.is('[data-silent]'))
            data.set('silent', 'true');

        if(form.attr('action') === undefined)
            return;




        axios({
            method: form.attr('method'),
            url: form.attr('action'),
            data: data
        }).then(response => {
            if (typeof callback === "function")
                callback(response);

            if (form.is('[data-on-success]'))
                FormOnSuccess[form.attr('data-on-success')](form, response.data);

            this.handleSuccess(response.data, form, elements);

            JsLoader.hide()
        }).catch(error => {
            if (typeof callback === "function")
                callback(error);

            let response = error.response;

            if (form.is('[data-on-error]'))
                FormOnError[form.attr('data-on-error')](form, response.data);

            //Clearing out any previous validation errors
            this.clearErrors(form);

            if(response && response.hasOwnProperty('data') && response.data.hasOwnProperty('errors')) {

                Object.keys(response.data.errors).forEach(item => {
                    let message = response.data.errors[item][0],
                        inputName = "";

                    //Converting dot notation into bracket notation to match the name attribute on inputs
                    item.split(".").forEach((part, index) => {
                        inputName += index === 0 ? part : ("[" + part + "]");
                    });

                    let $input = form.find('[name="' + inputName + '"]').length ? form.find('[name="' + inputName + '"]') : form.find('[name="' + inputName + '[]"]')

                    $input = $input.length ? $input : form.find('[data-form-error="'+ inputName +'"]');

                    this.addErrorToField($input, message);
                })
            }

            JsLoader.hide()
        })
    }

    /**
     * Handles clearing out any previous errors in the given selectors.
     *
     * @param selector
     */
    static clearErrors(selector) {
        $(':input, [data-form-error]',selector).removeClass('is-invalid')
            .siblings('.invalid-feedback').remove().end()
            .end();
    }

    static handleSuccess(response, form, elements) {

        delete window.modalInputChanges;

        //Clearing out any previous validation errors
        if(form !== null)
            this.clearErrors(form);

        //If there is a redirect uri returned from the server and isn't the current location
        if(response.hasOwnProperty('redirect_uri') && response.redirect_uri !== location.href && !$(form).is('[data-no-redirect]')) {

            location.href = response.redirect_uri;

        } else {


            let locationURL = form !== null && form.is('[data-modal-url]') ? form.data('modal-url') : location.href;

            //Showing success alert if comes back from the server
            if(response.session.hasOwnProperty('success'))
                Alerts.success(response['session']['success']);

            if(form !== null && !form.is('[data-silent]')) {
                //Reloading any tables
                Tables.reloadDataTables('body');

                //Closing any open modals
                if(!form.is('[data-modal-url]'))
                    Modals.closeOpenModal(form);

                //Scrolling to top of page
                if(form.hasClass('no-scroll') === false)
                    window.scrollTo(0, 0);
            }

            // this.closeFormLoader(form);

            //As the locations are the same we need to make a call to fetch the updated HTML for this page
            axios.get(locationURL, {
                params: {
                    elements: elements
                }
            }).then(response => {

                if(response.data.hasOwnProperty('html')) {

                    Object.keys(response.data.html).forEach(id => {

                        document.getElementById(id).outerHTML = response.data.html[id];

                        new Components('#' + id, false);
                    })
                }
            })
        }
    }

    /**
     *
     * Handles attaching an error message to a field and showing this field as invalid.
     *
     * @param field
     * @param message
     */
    static addErrorToField(field, message) {
        $(field).addClass('is-invalid')
            .parent()
            .append('<div class="invalid-feedback">' + message + '</div>');
    }

    static getRenderElements(form) {
        let elements = [];

        $(form).find('[name="elements[]"]').each((key, item) => {
            elements.push($(item).val());
        });

        return elements;
    }

    static autoSubmit(selector) {
        $(selector).find(':input.auto-submit, form.auto-submit :input').off('change').on('change', (e) => {
            let form = $(e.target).closest('form');
            form.submit();
        });
    }

    static formLoader(form) {
        let loaderEl = $(form).is('[data-formLoader]') ? $(form).closest($(form).data('formLoader')) :
            ($(form).closest('.modal-content').length ? $(form).closest('.modal-content') : $(form).closest('.card'))

        Utilities.loadingOverlay(loaderEl, !form.is('[data-silent]'));
    }
    static closeFormLoader(form) {
        let loaderEl = $(form).is('[data-formLoader]') ? $(form).closest($(form).data('formLoader')) :
            ($(form).closest('.modal-content').length ? $(form).closest('.modal-content') : $(form).closest('.card'));


        Utilities.removeLoadingOverlay(loaderEl);
    }

}
