export class JsLoader {
    static show () {
        this.setOptions();
        JsLoadingOverlay.show();
    }

    static hide () {
        JsLoadingOverlay.hide();
    }

    static setOptions() {
        JsLoadingOverlay.setOptions({
            'containerID': null,
        });
    }
}
