import {Forms} from "./components/Forms/Forms";
import {Modals} from "./components/Modals/Modals";
import {Tables} from "./components/Tables/Tables";
import {Dropdowns} from "./components/Dropdowns/Dropdowns";
import GetAddress from "./components/GetAddress";
import {Filepond} from "./components/Filepond";
import {InputMasks} from "./components/InputMasks";
import {DateRangePicker} from "./components/Daterangepicker";
import Utilities from "./components/Utilities";
import Signature from "./components/Signature";
import {ApiCalls} from "./components/ApiCalls/ApiCalls";
import Repeater from "./components/Repeater";
import GunOrderTotal from "./gunOrders/GunOrderTotal";
import {Alerts} from "./components/Alerts";
import GunServiceTotal from "./gunServices/GunServiceTotal";
import Wysiwyg from "./wysiwyg";
import CustomerSearch from "./eposNow/CustomerSearch";
import GunServiceMiscProduct from "./gunServices/GunServiceMiscProduct";
import GunOrderMiscProduct from "./gunOrders/GunOrderMiscProduct";

export class Components {
    constructor(selector) {

        Forms.ajaxSubmit(selector);
        Modals.init(selector);
        Tables.init(selector);
        Dropdowns.selectize(selector);
        GetAddress.initGetAddress(selector);
        Filepond.init(selector)
        InputMasks.init(selector)
        DateRangePicker.init(selector)
        Utilities.displayWhens(selector)
        Utilities.disableWhen(selector)
        Signature.init(selector)
        ApiCalls.init(selector)
        Repeater.JRepeater(selector);
        GunOrderTotal.init(selector);
        GunServiceTotal.init(selector);
        Wysiwyg.wysiwyg(selector)
        CustomerSearch.init(selector)
        GunServiceMiscProduct(selector)
        GunOrderMiscProduct(selector)

    }
}
