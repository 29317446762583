import {Alerts} from "../Alerts";

export class ApiCalls {
    static init(selector) {
        $('[data-api-call]', selector).on('click', function (e) {

            e.preventDefault();

            axios({
                url: $(this).attr('href'),
            }).then(response => {
                if(response.hasOwnProperty('data') && response.data.hasOwnProperty('success')){
                    Alerts.success(response.data.success)
                }
            });

        });
    }
}
