import {Modals} from "../Modals/Modals";
import Customer from "../Customer";


export class DropdownParams {

    static create(params, $element) {

        return {
            delimiter: ',',
            persist: false,
            create: function (input) {
                let valueKey = $element.data('src') !== undefined ? $element.data('src-value') ?? 'id' : 'value',
                    labelKey = $element.data('src') !== undefined ? $element.data('src-label') ?? 'name' : 'text'

                return {
                    [valueKey]: input,
                    [labelKey]: input,
                }
            }
        }
    }

    static customerOrder(params, $element) {

        return {
            create: function (input) {
                Modals.show('/customers/create', 'addCustomerCard', false, function (modal) {
                    let name = input.split(" ");

                    if (name[0] !== typeof undefined)
                        modal.find('[name="first_name"]').val(name[0]);

                    if (name[1] !== typeof undefined)
                        modal.find('[name="last_name"]').val(name[1]);

                    modal.find('form').attr('data-on-success', 'customerCreateSelectize');
                }, function (modal) {
                    let sApi = $('#customer_id')[0].selectize
                    sApi.unlock();
                });
            },
            onChange: function ($item) {
                let sApi = $element[0].selectize, itemData = this.options[$item]

                //Display if customer is verified or not
                if (itemData && itemData.searchable.verified) {
                    $('#customer-verification').removeClass('unverified').addClass('verified').text('Verified')
                } else {
                    $('#customer-verification').removeClass('verified').addClass('unverified').text('Unverified')
                }


                if (!itemData) {
                    $('#editCustomer').addClass('disabled')

                    $('.customerInfo').hide()
                    Customer.resetCustomerOrderInformation($('.customerInfo'))

                } else {
                    $('#editCustomer').removeClass('disabled')
                    $('#editCustomer').prop('href', '/customers/' + itemData.id + '/edit')

                    Customer.setCustomerOrderInformation($('.customerInfo'), itemData.searchable)
                    $('.customerInfo').show()
                }


            }
        }
    }

    static gunOrderItem(params, $element) {

        return {
            searchField: ['name', 'sku'],
            onChange: function ($item) {
                let sApi = $element[0].selectize, itemData = this.options[$item],
                    $eposNowElement = $('[name*="epos_now_id"]', $element.parents('tr')),
                    $priceElement = $('[name*="price"]', $element.parents('tr')),
                    $nameElement = $('[name*="name"]', $element.parents('tr'))

                $eposNowElement.val(itemData.epos_now_id);
                $priceElement.val(itemData.price);
                $nameElement.val(itemData.name);
            },
            render: {
                option: function (item, escape) {
                    return '<div class="container-fluid"><div class="row">' +
                        `<div class="col-12">${item.name} - ${item.sku}</div>` +
                        '</div></div>';

                },
                item: function (item, escape) {
                    return '<div class="selectize-item h-100">' +
                        `<div class="row h-100">${item.name} - ${item.sku}</div></div>`;
                }
            }
        }
    }

    static gunServiceItem(params, $element) {

        return {
            searchField: ['name', 'sku'],
            onChange: function ($item) {
                let sApi = $element[0].selectize, itemData = this.options[$item],
                    $priceElement = $('[name*="price"]', $element.parents('tr')),
                    $nameElement = $('[name*="name"]', $element.parents('tr')),
                    $labourElement = $('[name*="labour_cost"]', $element.parents('tr')),
                    $quantityElement = $('[name*="quantity"]', $element.parents('tr')),
                    $eposNowElement = $('[name*="epos_now_id"]', $element.parents('tr'))


                $labourElement.val(((itemData.price * $quantityElement.val()) * 0.20));
                $priceElement.val((itemData.price * $quantityElement.val()));

                $eposNowElement.val(itemData.epos_now_id)

                $nameElement.val(itemData.name);
            },
            render: {
                option: function (item, escape) {
                    return '<div class="container-fluid"><div class="row">' +
                        `<div class="col-12">${item.name} - ${item.sku}</div>` +
                        '</div></div>';

                },
                item: function (item, escape) {
                    return '<div class="selectize-item h-100">' +
                        `<div class="row h-100">${item.name} - ${item.sku}</div></div>`;
                }
            }
        }
    }

    static gunServiceServices(params, $element) {
        return {
            onChange: function ($item) {
                let sApi = $element[0].selectize, itemData = this.options[$item]

                // $($element[0]).change();

            }
        }
    }

    static livewire(params, $element) {
        return {
            onChange: function (value) {
                window.livewire.emit('set:setFromJS', this.$input.attr('wire:model'), value)
            }
        }
    }

    static costumeItem(params, $element) {

        return {
            onChange: function ($item) {
                let sApi = $element[0].selectize, itemData = this.options[$item],
                    $eposNowElement = $('[name*="epos_now_id"]', $element[0].parents('div')),
                    $nameElement = $('[name*="name"]', $element[0].parents('div'))

                $eposNowElement.val(itemData.epos_now_id);
                $nameElement.val(itemData.name);
            },
            render: {
                option: function (item, escape) {
                    return '<div class="container-fluid"><div class="row">' +
                        '<div class="col-4">' +
                        '<img class="w-100" src="' + (item.images && item.images.length ? item.images[0].src : ' ') + '">' +
                        '</div>' +
                        '<div class="col-8">' +
                        '<span class="title">' +
                        '<span class="name"><strong>' + escape(item.name) + '</strong></span>' +
                        '</span><br>' +
                        '<span class="description">' + escape(item.custom_excerpt) + '</span>' +
                        '</div>' +
                        '</div></div></div>';

                },
                item: function (item, escape) {
                    return '<div class="selectize-item h-100">' +
                        '<div class="row h-100">' +
                        '<div class="col-1 h-100">' +
                        '<img class="h-100" src="' + (item.images && item.images.length ? item.images[0].src : ' ') + '">' +
                        '</div>' +
                        '<div class="col-11 h-100">' +
                        '<span class="name"><strong>' + escape(item.name) + '</strong></span>' +
                        '<span class="description"> ' + escape(item.custom_excerpt) + ' </span>' +
                        '</div>' +
                        '</div></div></div>';
                }
            },

            ...DropdownParams.create(params, $element)
        }
    }

    static actor(params, $element) {
        return {
            onChange: function ($item) {
                let sApi = $element[0].selectize,
                    itemData = this.options[$item]

                $('input[name="basic_hours"]').val(itemData.searchable.basic_hours.time);
                $('input[name="rate"]').val(itemData.searchable.rate);
            },
            create: input => {
                Modals.show('/actors/create', 'addActorCard', false, modal => {
                    let name = input.split(" ");

                    if (name[0] !== typeof undefined)
                        modal.find('[name="first_name"]').val(name[0]);

                    if (name[1] !== typeof undefined)
                        modal.find('[name="last_name"]').val(name[1]);

                    modal.find('form').attr('data-on-success', 'actorCreateSelectize');
                }, function (modal) {
                    let sApi = $('#inputActor')[0].selectize
                    sApi.unlock();
                });
            }
        }
    }
}
