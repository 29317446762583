export class InputMasks {


    static init(selector) {

        $('input[type="monetary"]', selector).each(function () {
            if($.fn.inputmask === undefined)
                require('inputmask/dist/jquery.inputmask');

            $(this).inputmask({
                alias: 'numeric',
                groupSeparator: ',',
                rightAlign: false,
                digits: 2,
                digitsOptional: false,
                prefix: '£ ',
                placeholder: '0',
                removeMaskOnSubmit: true
            });
        });
        $('input[type="kilograms"]', selector).each(function () {
            if($.fn.inputmask === undefined)
                require('inputmask/dist/jquery.inputmask');

            $(this).inputmask({
                alias: 'numeric',
                groupSeparator: ',',
                rightAlign: false,
                digits: 2,
                digitsOptional: false,
                suffix: ' kg',
                placeholder: '0',
                removeMaskOnSubmit: true
            });
        });

        $('input[type="centimeter"]', selector).each(function () {
            if($.fn.inputmask === undefined)
                require('inputmask/dist/jquery.inputmask');

            $(this).inputmask({
                alias: 'numeric',
                groupSeparator: ',',
                rightAlign: false,
                digits: 2,
                digitsOptional: false,
                suffix: ' cm',
                placeholder: '0',
                removeMaskOnSubmit: true
            });
        });

        $('input[data-mask="time"]', selector).each(function () {
            if($.fn.inputmask === undefined)
                require('inputmask/dist/jquery.inputmask');

            $(this).inputmask({
                alias: "datetime",
                inputFormat: "HH:MM",
                placeholder: "0"
            });
        });

        $('input[data-mask="date"]', selector).each(function () {
            if($.fn.inputmask === undefined)
                require('inputmask/dist/jquery.inputmask');

            $(this).inputmask({
                alias: "datetime",
                inputFormat: "dd/mm/yyyy",
                placeholder: "0"
            });
        });

        $('input[data-mask="datetime"]', selector).each(function () {
            if($.fn.inputmask === undefined)
                require('inputmask/dist/jquery.inputmask');

            $(this).inputmask({
                alias: "datetime",
                inputFormat: "dd/mm/yyyy HH:MM",
                placeholder: "0"
            });
        });

        $('input[data-mask="sortCode"]', selector).each(function () {
            if($.fn.inputmask === undefined)
                require('inputmask/dist/jquery.inputmask');

            $(this).inputmask({
                mask: "99-99-99",
                placeholder: "0"
            });
        });

        $('input[data-mask="accountNumber"]', selector).each(function () {
            if($.fn.inputmask === undefined)
                require('inputmask/dist/jquery.inputmask');

            $(this).inputmask({
                mask: "99999999",
                placeholder: "0"
            });
        });

        $('input[data-mask="number"]', selector).each(function () {
            if($.fn.inputmask === undefined)
                require('inputmask/dist/jquery.inputmask');

            $(this).inputmask({
                alias: 'numeric',
                allowPlus: false,
                allowMinus: false,
                rightAlign: false
            });
        });

        $('input[data-mask="uk_mobile"]', selector).each(function () {
            if($.fn.inputmask === undefined)
                require('inputmask/dist/jquery.inputmask');

            $(this).inputmask({
                mask: "+44 70 9999 9999",
                definitions: {
                    "7": {
                        validator: "[7]"
                    },
                    "0": {
                        validator: "[1-9]"
                    }
                }
            });
        });

        $('input[data-mask="ukara"]', selector).each(function () {
            if($.fn.inputmask === undefined)
                require('inputmask/dist/jquery.inputmask');

            $(this).inputmask({
                mask: "XXX99999",
                definitions: {
                    'X' : {
                        validator: "[A-Za-z]",
                        casing: "upper"
                    }
                }
            });
        });

        $('input[data-mask="percent"]', selector).each(function () {
            if($.fn.inputmask === undefined)
                require('inputmask/dist/jquery.inputmask');

            $(this).inputmask({
                alias: "decimal",
                // placeholder: "0.00",
                // 'min': 0.00,
                'max': 99.99,
                suffix: " %",
                autoUnmask: true,
                digits: 2,
                digitsOptional: false,
                removeMaskOnSubmit: true
            });
        });

        $('input[data-mask="fma"]', selector).each(function () {
            if($.fn.inputmask === undefined)
                require('inputmask/dist/jquery.inputmask');

            $(this).inputmask({
                alias: "numeric",
                // placeholder: "0.00",
                // 'max': 99.99,
                prefix: "FMA ",
                autoUnmask: true,
                digits: 0,
                // digitsOptional: false,
                removeMaskOnSubmit: true
            });
        });
    }
}

