import {Components} from "../../components";
import Utilities from "../Utilities";
import {JsLoader} from "../JsLoader";
import ModalOnShow from "./ModalOnShow";

export class Modals {

    static init(selector) {
        $('a[data-toggle="use_modal"], [data-modal]', selector).off('click').on('click', e => {
            e.preventDefault();
            this.show($(e.currentTarget).attr('href'), $(e.currentTarget).data('element'), $(e.currentTarget), $(e.currentTarget).data('on-show'));
        })
    }

    static show(href, element, $target = false, callback = false, closeCallback = false) {


        JsLoader.show();

        axios.get(href, {
            params: {
                elements: [element]
            }
        }).then(response => {

            let content = $(response.data.html[element]),
                $modal = this.showModal(content.find('.card-body'), content.find('.title'), element);

            if(callback && typeof callback == "function")
                callback($modal, href, element, $target);

            if(callback && typeof callback == "string")
                ModalOnShow[callback]($modal, href, element, $target);

            JsLoader.hide();

            $modal.off('hide.bs.modal').on('hide.bs.modal', function (e) {

                if(window.modalInputChanges === true) {

                    if($modal.hasClass('programmatic')) {

                        delete window.modalInputChanges;
                        $modal.toggleClass('programmatic');
                        $modal.modal('hide');

                        Modals.closeCallback(closeCallback, $modal, href, element, $target);

                        $modal.remove();

                    } else {

                        e.preventDefault();
                        $modal.addClass('programmatic');

                        Utilities.confirmDialog('Your changes are not saved, would you like to save?', 'save', function () {

                            delete  window.modalInputChanges;
                            $modal.toggleClass('programmatic');
                            $('form', $modal).submit();
                            $modal.modal('hide');

                        }, function () {

                            delete window.modalInputChanges;
                            Modals.closeCallback(closeCallback, $modal, href, element, $target);
                            $modal.toggleClass('programmatic');
                            $modal.modal('hide');
                            $modal.remove();
                        });
                    }
                } else {
                    Modals.closeCallback(closeCallback, $modal, href, element, $target);
                    $modal.remove();
                }

            });

        })

    }

    static closeCallback(callback, $modal, href, element, $target) {
        if(callback && typeof callback == "function")
            callback($modal, href, element, $target);
    }

    static showModal(content, title, elementId)
    {
        $modal = $('#ajaxModal:not(.show)').clone();
        let $modal = $modal
            .find('.modal-content').addClass(elementId).end()
            .find('.modal-body').html(content).end()
            .find('.modal-title').html(title).end()
            .modal({
                keyboard: false,
                backdrop: 'static'
            }).modal('show');

        new Components($modal, false);


        this.inputChange($modal);


        return $modal;
    }

    static elementsModal(url, id, title)
    {
        axios.get(url, {
            params: {
                elements: [id]
            }
        }).then(response => {
            if(response.data.hasOwnProperty('html')) {
                let $modal = this.showModal(response.data.html[id], title)

                $('#'+ id + ' :input', $modal).each((key, element) => {
                    let $element = $(element);
                    if($element.is('textarea'))
                        $element.trumbowyg('disable')
                    else
                        $element.prop('disabled', true)
                })


            }
        })
    }

    static closeOpenModals() {
        delete window.modalInputChanges;
        $('.modal.show').modal('hide');
    }

    static closeOpenModal($element) {
        delete window.modalInputChanges;
        $element.parents('.modal.show').modal('hide');
    }

    static inputChange ($modal) {

        $(':input', $modal).on('change', function () {
            window.modalInputChanges = true;
        })

        $('.trumbowyg', $modal).on('tbwchange', function () {
            window.modalInputChanges = true;
        })

        $('.filepond', $modal).on('FilePond:processfile FilePond:removefile', function () {
            window.modalInputChanges = true;
        })

        $('select', $modal).on('selectize-loaded', function (e) {
            window.modalInputChanges = false;
        })
    }

    static modalLoader(target) {
        JsLoader.hide();
    }
    static closeModalLoader(target) {
        JsLoader.hide();
    }

    static addFormCallback($modal, href, element, $target) {
        $modal.find('form').attr('data-on-success', $target.data('add-form-callback'));
    }
}
