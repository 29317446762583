import Utilities from "../Utilities";
import {Forms} from "./Forms";
import route from "ziggy-js/src/js";

export default class FormConfirmation {

    static message(form) {
        Utilities.confirmDialog($(form).data('confirm'), $(form).data('confirm-type'), function () {
            Forms.submit(form);
        });
    }

    static actor(form) {

        let idInput = $('input[name="id"]',form);

        if ($(idInput).val() && route().params?.actor != $(idInput).val()) {
            axios.get(route('api.actors.show',{actor: $(idInput).val()})).then(({ data }) => {

                Utilities.confirmDialog((data.first_name+" "+data.last_name+" has this ID. Would you like to override?"), 'confirmation', function () {
                    console.log('hitting')
                    Forms.submit(form);
                });

            }).catch(data => {
                Forms.submit(form);
            })
        }else {
            Forms.submit(form);
        }

        return false
    }

    static customer(form) {

        let fmaInput = $('#fma_number',form);


        if ($(fmaInput).val() && $(fmaInput).is(":visible") && $(fmaInput).prop('defaultValue') != $(fmaInput).val()) {
            axios.get(route('api.customers.memberships.mfa.available',{
                value: $(fmaInput).val()
            })).then(({ data }) => {

                Forms.submit(form);

            }).catch(response => {

                Utilities.confirmDialog((response?.response?.data?.errors?.value+" has this ID. Would you like to override?"), 'confirmation', function () {
                    Forms.submit(form);
                });
            })
        }

        // Forms.submit(form);

        return false
    }

    static gunOrder(form) {
        const customerSApi = $('[name="customer_id"]', form)[0].selectize,
            customerData = customerSApi.options[customerSApi.getValue()]


        //If hasn't been customer has been selected, allow the form to submit so the system handles validation
        if(!customerData || (customerData.searchable && customerData.searchable.verified)) {
            Forms.submit(form);
        } else {
            const message = "The customer is not verified. Please confirm if you'd like to proceed creating the order"

            Utilities.confirmDialog(message, 'confirmation', function () {
                Forms.submit(form);
            });
        }
    }
}
