import helpers from "../helpers";

export default class GunOrderTotal {

    static init(selector) {
        this.gunOrderChanges(selector)
    }

    /**
     * Post to total endpoint and handle the response.
     * Set the order line totals
     * Set the overall total
     *
     * @param params
     */
    static calculate(params) {
        axios
            .post('/api/gun-orders/total', params)
            .then(function (response) {
                const orderLines = GunOrderTotal.getOrderLines();

                response.data.orderLines.forEach(function (data, index) {
                    const $orderLine = $(orderLines[index])

                    //Pick the keys which are required
                    const subset = (({price, tax, subtotal, total}) => ({price, tax, subtotal, total}))(data)

                    helpers.setElementContent('.', subset, $orderLine)
                })

                helpers.setElementContent('#', response.data.overallTotal)

            });
    }

    /**
     * Capture all changes which result in the totals needing to be re-calculated
     *
     * @param selector
     */
    static gunOrderChanges(selector) {

        $('[order-variable]', GunOrderTotal.getOrderLines()).unbind("change").bind("change", function () {
            console.log(GunOrderTotal.getOrderData(), 222)
            GunOrderTotal.calculate(GunOrderTotal.getOrderData())
        });
    }

    /**
     * Get all order data and return
     *
     */
    static getOrderData() {
        let APIParams = {}
        APIParams.orderLines = []

        this.getOrderLines().each((index, element) => {
            APIParams.orderLines.push(this.getOrderLineData($(element)))
        });

        return APIParams;

    }

    /**
     * Gets the order line data for a single row
     *
     * @param $element
     */
    static getOrderLineData($element) {
        return {
            'woocommerce_id': $('[name*="woocommerce_id"]', $element).val(),
            'epos_now_id': $('[name*="epos_now_id"]', $element).val(),
            'price': $('[name*="price"]', $element).inputmask('unmaskedvalue'),
            'name': $('[name*="name"]', $element).val(),
            'tax': $('[name*="tax"]', $element).inputmask('unmaskedvalue'),
            'quantity': $('[name*="quantity"]', $element).val(),
            'two_tone': $('[name*="two_tone"]', $element).val(),
        }
    }

    /**
     * Return all the order line items
     */
    static getOrderLines() {
        return $('[data-repeater-list="lines"] [data-repeater-item]:visible');
    }
}
