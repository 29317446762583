import {DropdownParams} from "./DropdownParams";

export class Dropdowns {

    static selectize(selector) {

        if($(selector).is('select')) {
            Dropdowns.initSelectizeHandler($(selector));
        }

        $(selector).find('select:not(.selectize-template)').each(function () {
            Dropdowns.initSelectizeHandler($(this));
        })
    }

    static initSelectizeHandler($element) {
        if($.fn.selectize === undefined)
            require('selectize');


        let params = {},
            selectize = undefined,
            src = "",
            selected = "";

        if((src = $element.data('src')) !== undefined) {

            params = Object.assign(params, {
                preload: true,
                valueField: $element.data('src-value') ?? 'id',
                labelField: $element.data('src-label') ?? 'name',
                searchField: $element.data('src-label') ?? 'name',
                load: function(query, callback) {
                    Dropdowns.selectizeAxiosLoad($element, src, selectize, callback, query);
                },
            });
        }
        else {
            /** If src not set it will allow a selected option */
            if ((selected = $element.data('selected')) !== undefined){
                $element.val(selected);
            }
        }

        //Loading the specific parameters for this dropdown
        if($element.data('init') !== undefined)
            params = Object.assign(params, DropdownParams[$element.data('init')](params, $element));


        selectize = $element.selectize(params)[0].selectize;
    }

    static selectizeAxiosLoad($element, src, selectize, callback, query) {
        if(query === undefined || query === '')
            query = []

        axios.get(src, {
            params: {
                query: query,
                selectize: true
            }
        }).then(response => {

            //If responses data is instance of laravel collection resource it will have additional data key
            callback(response.data.hasOwnProperty('data') ? response.data.data : response.data);

            if($element.data('selected') !== undefined) {

                if(!isNaN($element.data('selected'))) {
                    selectize.addItem($element.data('selected'), !$element.is('[data-select-not-silent]'));
                } else if (Array.isArray($element.data('selected'))) {
                    $element.data('selected').forEach(item => {
                        selectize.addItem(item, !$element.is('[data-select-not-silent]'));
                    })
                } else {
                    selectize.addItem($element.data('selected'), !$element.is('[data-select-not-silent]'));
                }

            } else if ($element.is('[data-select-first]')){

                selectize.refreshItems();

                if(response.data[0] !== undefined)
                    selectize.addItem(response.data[0].id)
                else
                    selectize.addItem(selectize.options[Object.keys(selectize.options)[0]].id)

            }


            $element.trigger('selectize-loaded');
        })
    }
}
